import '../css/App.css';
import {useEffect, useState} from "react";
import axios from "axios";
import moment from "moment";
import $ from 'jquery';

function Create(props) {
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(groups[0])

    useEffect(() => {
        axios.get(
            process.env.REACT_APP_API_URL + 'group/')
            .then(res => {
                setGroups(res.data)
            })
    }, [])

    function createFormular(){
        let groupSelec = $(".groupSelect").val();
        let date = $("input[name=date]").val().replace("T", " ");
        let name = $("input[name=formName]").val();
        let description = $("#comment").val();
        let bindingDeadline = ($(".checkboxBindingDeadline").prop('checked'));
        if (moment(date) > moment()){
            axios.post(
                process.env.REACT_APP_API_URL + 'form/', [name, groupSelec, date, description, bindingDeadline])
                .then(res => {
                    if (res.data === "success"){
                        window.location.href = '/?form='+name
                    }else {
                        $(".alert-danger").removeClass("d-none");
                    }
                })
        }else {
            $(".alert-danger").removeClass("d-none");
        }
    }


    return (
        <div className="Create">
            <div className="form-group row">
                <label htmlFor="inputPassword" className="col-sm-5 col-form-label"><h3>Namen: </h3></label>
                <div className="col-sm-7 mt-2">
                    <input type="text" name="formName" className="form-control" id="inputPassword" placeholder="Gebe einen Namen ein" value={props.form} />
                </div>
            </div>

            <div className="form-group row mt-3">
                <label htmlFor="inputPassword" className="col-sm-5 col-form-label"><h3>Gruppe: </h3></label>
                <div className="col-sm-7 mt-2">
                    <div className="input-group mb-3 bg-secondary">
                        <select className="form-select text-center groupSelect" id="inputGroupSelect01">
                            <option defaultChecked={true} disabled={true}>Wähle aus...</option>
                            {groups.map(item =>
                                <option value={item.id} key={item.id}>{item.name}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>

            <div className="form-group row mt-3">
                <label htmlFor="inputPassword" className="col-sm-5 col-form-label"><h3>Datum: </h3></label>
                <div className="col-sm-7 mt-2">
                    <input type="datetime-local" id="start" name="date" className="fs-4"
                           defaultValue={moment().format('YYYY-MM-DD HH:mm')} min={moment().format('YYYY-MM-DD, HH:mm')} />
                </div>
            </div>

            <div className="form-group mt-4">
                <label htmlFor="comment"><h3>Bemerkungen: </h3></label>
                <textarea className="form-control text-center" id="comment" rows="3"></textarea>
            </div>

            <div className="fs-5 mt-3">
                <input className="form-check-input checkboxBindingDeadline"
                       type="checkbox" defaultChecked={true} id="checkboxBindingDeadline" /> <span className="ms-2">Verbindliches Zeitlimit</span>
            </div>

            <button className="btn btn-success mt-4 fs-4" onClick={createFormular}>Formular erstellen</button>

            <div className="alert alert-danger d-none mt-3" role="alert">
                Leider ist etwas schief gelaufen...!
            </div>
        </div>
    );
}

export default Create;
