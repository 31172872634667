import '../css/App.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useState, useEffect} from "react";

function SearchPerson(props) {
    return (
        <div className="Deadline text-center mt-4 w-75">
            <div className="input-group mb-3">
                <span className="input-group-text" id="searchIcon"><FontAwesomeIcon icon="search" size="xl" /></span>
                <input type="text" className="form-control text-center fs-5" name="searchString" placeholder="Suche eine Person" aria-describedby="searchIcon"
                       onChange={(event) => props.getPersonText(event)}/>
            </div>
        </div>
    );
}

export default SearchPerson;
