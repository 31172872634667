import '../css/App.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import 'moment/locale/de'
import {useState, useEffect} from "react";

function Deadline(props) {
    moment.locale('de');
    const [timeRemain, setTimeRemain] = useState();
    const [timeText, setTimeText] = useState("");
    const [dateText, setDateText] = useState("");

    useEffect(() => {
        setTimeRemain(props.deadline)
        if (moment.duration(moment(props.deadline).diff(moment(new Date())))._milliseconds >= 0) {
            setTimeText(moment(props.deadline).fromNow())
        }else {
            setTimeText("--- BEENDET ---")
        }
        setDateText(moment(props.deadline).format('Do MMMM YYYY / HH:mm'))
    }, [props.deadline])

    return (
        <div className="Deadline card text-center col-5 bg-secondary w-100">
            <div className="card-body">
                <h2 className="card-title"><FontAwesomeIcon icon="clock" size="2x" className="float-start"/>{timeText}</h2>
                <p className={"card-text " + (props.bindingDeadline && "text-warning")}>RM Termin: {dateText}</p>
            </div>
        </div>
    );
}

export default Deadline;
