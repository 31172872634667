import '../css/App.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";
import axios from "axios";
import $ from 'jquery';
import moment from "moment";
import TextareaAutosize from 'react-textarea-autosize';

function TableRM(props) {
    const [persons, setPersons] = useState([]);
    const [timeOver, setTimeOver] = useState(0);
    const [comment, setComment] = useState("");

    useEffect(() => {
        setPersons(props.persons)
    }, [props.persons])

    useEffect(() => {
        setTimeOver(moment.duration(moment(props.deadline).diff(moment(new Date())))._milliseconds)
    }, [props.deadline])

    function changeRMStatus(person){
        if(person.rm === 0 && ((props.bindingDeadline === 1 && timeOver >= 0) || props.bindingDeadline === 0)){
            axios.put(
                process.env.REACT_APP_API_URL + 'person/' + person.id, {row: "rm", value: 1})
                .then(res => {
                    $(".person" + person.id).removeClass("text-red").addClass("text-green").removeClass("thumbsUP");
                    props.func(1)
                })
        }
    }

    function changeComment(person){
        var newComment = $('textarea[name=comment'+person.id+']').val();
        axios.put(
            process.env.REACT_APP_API_URL + 'person/' + person.id, {row: "comment", value: newComment})
            .then(res => {
                $("comment"+person.id).val(newComment);
                $(".saveButton" + person.id).addClass("d-none")
            })
    }

    return (
        <div className="TableRM w-75">
            <table className="table text-white mt-4">
                <tbody>
                {
                    persons.map(item =>
                        <tr key={item.id}>
                            <td className={item.rm === 0 ? "text-red col-2 person"+item.id : 'text-green col-2 person'+item.id}>{item.name}</td>
                            <td className="col-2">
                                <FontAwesomeIcon icon="thumbs-up" size="2x" className={item.rm === 0 ? "text-red thumbsUP person"+item.id : 'text-green person'+item.id}
                                                 onClick={() => {changeRMStatus(item); ((props.bindingDeadline === 1 && timeOver >= 0) || props.bindingDeadline === 0) ? item.rm = 1 : item.rm = 0}} />
                            </td>
                            <td>
                                    <div className="p-2 mb-1 bg-white text-dark rounded d-flex divCommentSave"  style={{alignItems: "end"}}>
                                        <TextareaAutosize
                                            defaultValue={item.comment}
                                            name={"comment" + item.id}
                                            style={{border: "none", outline: "none", resize: "none", flexGrow: 1}}
                                            className={"form-control commentTextarea text-start comment"+item.id}
                                            id="comment"
                                            onChange={() => {$(".saveButton" + item.id).removeClass("d-none")}}
                                            placeholder="allfällige Bemerkung (optional)"
                                        />
                                        <button className={"btn btn-primary d-none saveButton" + item.id} onClick={() => changeComment(item)}
                                                style={{fontSize: "13px", marginLeft: "0.8rem", height: "35px", flexShrink: "0"}}>Speichern</button>
                                    </div>
                                 </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>
    );
}

export default TableRM;
