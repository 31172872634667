import '../css/App.css';
import Deadline from "./Deadline";
import TableRM from "./TableRM";
import {useState, useEffect} from "react";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SearchPerson from "./SearchPerson";
import Create from "./Create";

function App() {
    const queryParameters = new URLSearchParams(window.location.search)
    const formURL = queryParameters.get("form");
    const [form, setForm] = useState([]);
    const [group, setGroup] = useState([]);
    const [persons, setPersons] = useState([]);
    const [visualPersons, setVisualPersons] = useState([]);
    const [rmCount, setRMCount] = useState(0);
    const [searchString, setSearchString] = useState("");
    const [needCreate, setNeedCreate] = useState(false)
    const [description, setDescription] = useState("");

    const count = (data) => {
        setRMCount(rmCount + 1)
    }

    function getPersonText(event) {
        setSearchString(event.valueOf().target.value);
    }

    useEffect(()=>{
        let searchedPersons = []
        persons.forEach(function (item, index){
            if (item.name.toLowerCase().includes(searchString.toLowerCase())){
                searchedPersons.push(item)
            }
        })
        setVisualPersons(searchedPersons)
    }, [searchString])

    useEffect(() => {
        axios.get(
            process.env.REACT_APP_API_URL + 'form/' + formURL)
            .then(res => {
                if(res.data !== "nonExistent"){
                    setRMCount(0)
                    setForm(res.data);
                    setGroup(res.data.group)
                    setPersons(res.data.persons);
                    setVisualPersons(res.data.persons)
                    setDescription(res.data.description)
                    var tempCount = 0
                    res.data.persons.forEach(function (item, index){
                        if(item.rm === 1){
                            tempCount+=1
                        }
                    })
                    setRMCount(tempCount)
                }else {
                    setNeedCreate(true)
                }
            })
    }, [])

    if(!needCreate){
        return (
            <div className="App container mt-4">
                <div className="row">
                    <div className="col-6">
                        <h1 >{rmCount} von {persons.length} Rückmeldungen</h1>
                        <span>{description}</span>
                    </div>
                    <div className="col-5">
                        <Deadline deadline={form.targettime} bindingDeadline={form.bindingDeadline}/>
                    </div>
                </div>
                <SearchPerson getPersonText = {getPersonText} />
                <TableRM persons={visualPersons} func={count} searchString = {searchString} bindingDeadline={form.bindingDeadline} deadline={form.targettime}/>
            </div>
        );
    }else {
        return (
            <div className="App container mt-4">
                <Create form = {formURL}/>
            </div>
        )
    }
}

export default App;
